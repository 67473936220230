import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";

import "./form.css";

class walmart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "WA",
      name: "",
      date: "",
      business: "",
      birth_date: "",
      email: "",
      phone: 0,
      file: null,
      document:0,
      fact: 0,
      quantity: 0,
      box:0,
      photo_fact: {},
      brandsArray: [],
      products: [],
      prodIdSelected: null,
      prodLabelSelected: "",
      prodsArray: [],
      checkHandler: true,
    };
  }

  jsonParser = (id, quantity) => {
    let dataToSend = {
      id: id,
      quantity: quantity,
    };
    this.state.brandsArray.push(dataToSend);
    console.log(this.state.brandsArray);
  };

  getCatalog = () => {
    axios
      .get(`https://vqmfht5ahq.us-east-1.awsapprunner.com/api/v1/lead/catalog/`)
      .then(
        function (response) {
          this.setState({
            products: response.data.map((product) => {
              return {
                value: product.id,
                label: product.name,
              };
            }),
          });
          console.log(this.state.products);
        }.bind(this)
      )
      .catch(
        function (error) {
          alert("Error al obtener el catalogo", error.message);
        }.bind(this)
      );
  };

  postForm = (brands) => {

    if (this.state.name === "" ) 
    {
      alert("Llenar todos los campos disponibles!")
    }else{

      const formData = new FormData();
      formData.append("name", this.state.name);
      formData.append("date", this.state.date);
      formData.append("business", this.state.business);
      formData.append("birthday", this.state.birth_date);
      formData.append("email", this.state.email);
      formData.append("phone", this.state.phone);
      formData.append("invoice", this.state.fact);
      formData.append("box", this.state.box);
      formData.append("document", this.state.document);
      formData.append("purchase", brands);
      formData.append("invoice_picture", this.state.photo_fact);
      formData.append("company", this.state.company);
  
      axios
        .post(
          `https://vqmfht5ahq.us-east-1.awsapprunner.com/api/v1/lead/`,
          formData
        )
        .then(function (response) {
          alert("¡Formulario enviado exitosamente!");
        })
        .catch(function (error) {
          alert("Error al enviar formulario");
        });

    }

  };

  componentDidMount() {
    this._isMounted = true;
    this.getCatalog();
  }

  render() {
    return (
      <div className="form_containermc">
        <Form className="walmart-bg">
            <br />
            <br />
            <br />
          <Col sm="8" md="5">
            <Row>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  required
                  onChange={(e) => {
                    this.setState({
                      name: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <br />
            <br />
            <Row>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Fecha</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Date"
                  required
                  onChange={(e) => {
                    this.setState({
                      date: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <br />
            <br />
            <Row>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Establecimiento</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Establecimiento"
                  required
                  onChange={(e) => {
                    this.setState({
                      business: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <br />
            <br />
            <Row>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="birth"
                  required
                  onChange={(e) => {
                    this.setState({
                      birth_date: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <br />
            <br />
            <Row>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Correo Electrónico"
                  required
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <br />
            <br />
            <Row>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Teléfono"
                  maxLength="8"
                  required
                  onChange={(e) => {
                    this.setState({
                      phone: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <br></br>
            <Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Documento DPI/Pasaporte</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  required
                  onChange={(e) => {
                    this.setState({
                      document: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <br />
            
            <Row>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>No. Factura</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="No. Factura"
                  required
                  onChange={(e) => {
                    this.setState({
                      fact: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <br />
            Selecciona los productos de tu compra y la cantidad de cada uno:
            <br />
            <br />
            <div className="containerProds5">
            <Row
                xs="2"
                sm="8"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <div style={{ width: "60%" }}>
                  <Form.Label>Cajas</Form.Label>
                </div>
                <div style={{ width: "30%", marginLeft: "1vh" }}>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Control
                      type="number"
                      placeholder="0"
                      required
                      onChange={(e) => {

                       this.setState({box: e.target.value})

                      }}
                    />
                  </Form.Group>
                </div>
              </Row>
              <hr />
            <Form.Label>Otro Producto</Form.Label>
          
              <div style={{display: "flex", flexDirection:"row", justifyContent:"space-around"}}>

                <div style={{ width: "60%" }}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="number"
                      placeholder="Producto"
                      required
                      onChange={(e) => {
                        this.setState({
                          otherProd: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </div>
                <div style={{ width: "20%" }}>
                  <Form.Control
                    type="number"
                    placeholder="0"
                    required
                    onChange={(e) => {
                      this.setState({
                        quantity: e.target.value,
                      });
                      if (e.target.value > 1) {
                        this.setState({
                          checkHandler: true,
                        });
                      } else {
                        this.setState({
                          checkHandler: false,
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <hr />

              <Form.Label>Listado de Productos</Form.Label>
              {this._isMounted &&
                this.state.products &&
                this.state.products.map((product) => {
                  return (
                    <>
                      <Row
                        xs="2"
                        sm="8"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "20px",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "60%" }}>
                          <Form.Label>{product.label}</Form.Label>
                        </div>
                        <div style={{ width: "30%", marginLeft: "1vh" }}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="number"
                              placeholder="0"
                              required
                              onChange={(e) => {
                                
                                if (e.target.value > 0) {
                                  this.jsonParser(
                                    product.value,
                                    parseInt(e.target.value)
                                  );
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                      </Row>
                    </>
                  );
                })}
            </div>
            <br />
            <Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Foto Factura</Form.Label>
                <Form.Control
                  type="file"
                  required
                  onChange={(e) => {
                    this.setState({
                      photo_fact: e.target.files[0],
                    });
                  }}
                />
              </Form.Group>
            </Row>
          </Col>
          <Col sm="8" md="2">
            <br></br>
            <br />
            <br />
            <br></br>
            <Row>
              <Button
                className="buttD"
                variant="light"
                onClick={() => {
                  this.setState({
                    brands: JSON.stringify([...new Set(this.state.brandsArray)]),
                  });
                  this.postForm(JSON.stringify([...new Set(this.state.brandsArray)]));
                }}
              >
                Enviar
              </Button>
            </Row>
          </Col>
        </Form>
      </div>
    );
  }
}
export default walmart;
