import React from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { Wheel } from 'react-custom-roulette'
import Confetti from 'react-confetti'
import bucana from '../assets/Buchanans.png'
import parr from '../assets/Old-Parr.png'
import labelBlack from '../assets/Black-Label.png'
import labelRed from '../assets/red.png'
import cavit from '../assets/Cavit.png'
import Estrella from '../assets/Estrella.png'
import fuego from '../assets/fuego.png'
import Jimador from '../assets/Jimador.png'
import Stoli from '../assets/Stoli.png'


import "../forms/form.css";
import audio from '../assets/audio/ruleta.mp3';
import audiowin from '../assets/audio/win.mp3';

const data = [
  { option: 'Ganador', style: { background: '-webkit-linear-gradient(#eee, #333)', textColor: 'black' } },
  { option: 'Perdedor', style: { backgroundColor: 'red', textColor: 'black' } },
  { option: 'Perdedor', style: { backgroundColor: 'blue', textColor: 'black' } },
  { option: 'Perdedor', style: { backgroundColor: 'yellow', textColor: 'black' } },
  { option: 'Perdedor', style: { backgroundColor: 'white', textColor: 'black' } },
  { option: 'Perdedor', style: { backgroundColor: 'navy', textColor: 'black' } },
  { option: 'Perdedor', style: { backgroundColor: 'orange', textColor: 'black' } },
  { option: 'Perdedor', style: { backgroundColor: 'purple', textColor: 'black' } },
]


class game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spin: false,
      prize: 0,
      productPrize: null,
      showspin: true,
      timeConfetti: false,
      random: [
      /* 9,7, */
      1,1,1,1,2,2,2,2,4,4,4,4,0,0,0,0,0,0,3,3,3,3,3,3,5,5,5,5,5,5,6,6,6,6,6,6,8,8,8,8,8,8  
      ]
    };
  }



  handleSpinClick = () => {
   let newPrizeNumber = 0
    if (window.location.pathname === "/" ) {
       newPrizeNumber = Math.floor(Math.random() * this.state.random.length);
    } else {
       newPrizeNumber = Math.floor(Math.random() * this.props.data.length);
    }

    this.setState({
      spin: !this.state.spin,
      prize : newPrizeNumber
    })
  };

  postRoulette = () => {
    let newPrizeNumber = 0
    if (window.location.pathname === "/" ) {
      newPrizeNumber = this.props.data[this.state.random[this.state.prize]] && this.props.data[this.state.random[this.state.prize]].id;
    }else {
      newPrizeNumber = this.props.data[this.state.prize] && this.props.data[this.state.prize].id
    }
    const formData = new FormData();
    formData.append("lead", this.props.lead);
    formData.append("prize", newPrizeNumber );
    axios.post(
      `https://vqmfht5ahq.us-east-1.awsapprunner.com/api/v1/lead/roulette/`,
      formData
    ).then(function (response) {
      // console.log(response.data, 'response');
      //alert("¡Formulario enviado exitosamente!");
      this.setState({
        // showspin: true,
      })
    }.bind(this)
    ).catch(function (error) {
      //console.log(error, 'error')
      alert("Error al enviar formulario");
    }.bind(this)
    );

  };

  playAudio = () => {
    new Audio(audio).play();
  }
  playAudioWin = () => {
    new Audio(audiowin).play();
  }

  timeConfettiWAIT = () => {
    setTimeout(
      function () {
        this.setState({
          timeConfetti: true
        })
        this.postRoulette()
        this.playAudioWin()
      }
        .bind(this),
      8000
    );
  }
  componentDidMount() {
    this._isMounted = true;
    console.log(window.location.pathname, 'oath')
  }

  render() {
    return (
      <div style={{
        textAlign: '-webkit-center', paddingTop: '40%'
      }}>
        <div class={
          this.props.show && "animate__animated animate__fadeInUp animate__delay-0s"
        }
        >
          <div
            style={{ width: '100%' }}
            className="animate__animated animate__pulse animate__infinite">
            <img
              style={{ width: '60%', top: '-25vh', right: '23vh', transform: 'rotate(147deg)', position: 'absolute' }}
              src={window.location.pathname === "/" ? bucana : cavit} />
          </div>
          <div
            style={{ width: '100%' }}
            className="animate__animated animate__pulse animate__infinite">
            <img
              style={{ width: '60%', top: '-10vh', right: '2vh', transform: 'rotate(0deg)', position: 'absolute' }}
              src={window.location.pathname === "/" ?parr : Estrella} />
          </div>
          <p
            style={{ color: 'white', fontSize: '28px', fontWeight: 'bold' }}
            className="animate__animated animate__pulse animate__delay-1s" >
            ¡PARTICIPA Y GANA!
          </p>
          <Wheel
            mustStartSpinning={this.state.spin}
            prizeNumber={window.location.pathname === "/" ? this.state.random[this.state.prize] : this.state.prize }
            data={this.props.data}
           //backgroundColors={['#3e3e3e', '#df3428']}
            textColors={['#ffffff']}
            perpendicularText={false}
            spinDuration={0.7}
            startingOptionIndex={2}
            radiusLineWidth={2}
            radiusLineColor={window.location.pathname === "/" ? 'rgb(167, 3, 3)' : '#1404fe'}
            innerBorderWidth={5}
            innerBorderColor={'black'}
            innerRadius={5}
            outerBorderWidth={3}
            outerBorderColor={'black'}
            fontSize={15}
            optionSize={2}
          />
          <div
            style={{ width: '100%', zIndex: 0 }}
            className="animate__animated animate__pulse animate__infinite">
            <img
              style={{ width: (window.location.pathname === "/" ?'100%' : '80%'), top: '-27vh', right: '20vh', transform: 'rotate(27deg)', position: 'absolute' }}
              src={window.location.pathname === "/" ? labelBlack : fuego} />
          </div>
          <div
            style={{ width: '100%',  }}
            className="animate__animated animate__pulse animate__infinite fixed">
            <img
            
              style={{ width: '60%', top: '-5vh', right: '4vh', transform: 'rotate(350deg)', position: 'absolute', zIndex: 10}}
              src={window.location.pathname === "/" ? labelRed : Jimador} />
          </div>
        </div>
        <div class={
          this.props.show && "animate__animated animate__fadeInUp animate__delay-1s"
        }
        >
          {
            this.state.showspin === true &&
            <Button
              className="animate__animated animate__pulse animate__infinite"
              style={{ borderColor: 'red', borderRadius: '25px', backgroundColor: 'red', marginTop: '20px' }}
              onClick={
                () => {
                  this.setState({
                    showspin: false
                  })
                  this.handleSpinClick()
                  this.timeConfettiWAIT()
                  this.playAudio()
                }
              }>
              ¡GIRAR!
            </Button>
          }

        </div>
        {window.location.pathname !== "/" && 
             <div
             style={{ width: '100%',  }}
             className="animate__animated animate__pulse animate__infinite fixed">
             <img
             
               style={{ width: '60%', top: '1vh', right: '24vh', transform: 'rotate(84deg)', position: 'absolute', zIndex: 10}}
               src={window.location.pathname === "/" ? labelRed : Stoli} />
           </div>
        }
        {
          (this.state.timeConfetti === true) &&
          <Confetti
            style={{ zIndex: 8 }}
            /*  drawShape={ctx => {
               ctx.beginPath()
               for (let i = 0; i < 32; i++) {
                 const angle = 0.3 * i
                 const x = (0.2 + (1.5 * angle)) * Math.cos(angle)
                 const y = (0.2 + (1.5 * angle)) * Math.sin(angle)
                 ctx.lineTo(x, y)
               }
               ctx.stroke()
               ctx.closePath()
             }} */
            numberOfPieces={200}
          />
        }


      </div >

    );
  }
}
export default game;
