import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Game from "../game/game.jsx";

import "./form.css";

class super24 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showspin: false,
      loading: false,
      lead: null,
      company: "S2",
      name: "",
      date: "",
      business: "",
      birth_date: "",
      email: "",
      phone: 0,
      document:"",
      file: null,
      fact: 0,
      quantity: 0,
      box: 0,
      dpcument: 0,
      photo_fact: null,
      brandsArray: [],
      products: [],
      productsData: [],
      prodIdSelected: null,
      prodLabelSelected: "",
      prodsArray: [],
      checkHandler: true,
      show:false
    };
  }

  jsonParser = (id, quantity) => {
    let dataToSend = {
      id: id,
      quantity: quantity,
    };
    this.state.brandsArray.push(dataToSend);
    console.log(this.state.brandsArray);
  };

  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  
  sendData = () => {
    setTimeout(
      function() {
          this.toggleLoading()
      }
      .bind(this),
      2000
    )
    this.setState({ 
      showspin: true ,
      show: true
    });
  }

  getCatalog = () => {
    //this.toggleLoading()
    axios
      .get(`https://vqmfht5ahq.us-east-1.awsapprunner.com/api/v1/lead/catalog/?company=${this.state.company}`)
      .then(
        function (response) {
          //this.toggleLoading();
          console.log('entroo')
          this.setState({
            products: response.data.map((product) => {
              return {
                value: product.id,
                label: product.name,
              };
            }),
          });    
        }.bind(this)
      )
      .catch(
        function (error) {
          alert("Error al obtener el catalogo", error.message);
        }.bind(this)
      );
    //this.toggleLoading()

  };

  getCatalogPrize = () => {
    //this.toggleLoading()
    axios
      .get(`https://vqmfht5ahq.us-east-1.awsapprunner.com/api/v1/lead/catalog_prize/?company=${this.state.company}`)
      .then(
        function (response) {
          //this.toggleLoading();
          console.log('entroo')
          this.setState({
            productsData: response.data.map((product) => {
              return { 
              id:product.id,  option: product.name, style: { backgroundColor: product.backgroundColor, textColor: product.textColor } 
              }

            }),
          });    
        }.bind(this)
      )
      .catch(
        function (error) {
          alert("Error al obtener el catalogo", error.message);
        }.bind(this)
      );
    //this.toggleLoading()

  };

 /*  getRoulette = () => {
    //this.toggleLoading()
    axios
      .get(`http://localhost:8000/api/v1/lead/roulette/`)
      .then(
        function (response) {
          //this.toggleLoading();
          console.log('entroo')
          this.setState({
            products: response.data.map((product) => {
              return {
                value: product.id,
                label: product.name,
              };
            }),
          });    
        }.bind(this)
      )
      .catch(
        function (error) {
          alert("Error al obtener el catalogo", error.message);
        }.bind(this)
      );
    //this.toggleLoading()

  }; */

  postForm = (brands) => {
    console.log(brands.length, 'length')
    this.toggleLoading();
    if (this.state.name === "" || this.state.business === "" || 
    this.state.email === "" || this.state.document === "" ||
    this.state.phone === ""|| this.state.photo_fact === null ||
    this.state.company === "" || brands.length === 2) {
    this.toggleLoading();
      alert("Llenar todos los campos disponibles!")
      this.setState({
        loading: false,
      })
    } else {
      const formData = new FormData();
      formData.append("name", this.state.name);
      //formData.append("date", this.state.date);
      formData.append("business", this.state.business);
      //formData.append("birthday", this.state.birth_date);
      formData.append("email", this.state.email);
      formData.append("document", this.state.document);
      formData.append("phone", this.state.phone);
      //formData.append("box", this.state.box);
      //formData.append("invoice", this.state.fact);
      formData.append("purchase", brands);
      formData.append("invoice_picture", this.state.photo_fact);
      formData.append("company", this.state.company);
      axios.post(
          `https://vqmfht5ahq.us-east-1.awsapprunner.com/api/v1/lead/`,
          formData
        ).then(function (response) {
         // console.log(response.data, 'response');
          //alert("¡Formulario enviado exitosamente!");
          this.setState({
            showspin: true,
            show: true,
            loading: !this.state.loading,
            lead: response.data && response.data.id
          })
        }.bind(this)
        ).catch(function (error) {
          //console.log(error, 'error')
          this.toggleLoading()
          alert("Error al enviar formulario");
        }.bind(this)
        );
    }
  };

 /*  postRoulette = (prize) => {
    this.toggleLoading();
      const formData = new FormData();
      formData.append("lead", this.state.lead);
      formData.append("prize", prize);
      axios.post(
          `http://localhost:8000/api/v1/lead/roulette/`,
          formData
        ).then(function (response) {
         // console.log(response.data, 'response');
          //alert("¡Formulario enviado exitosamente!");
          this.setState({
            showspin: true,
            loading: !this.state.loading
          })
        }.bind(this)
        ).catch(function (error) {
          //console.log(error, 'error')
          this.toggleLoading()
          alert("Error al enviar formulario");
        }.bind(this)
        );
    
  }; */

  componentDidMount() {
    this._isMounted = true;
    this.getCatalog();
    this.getCatalogPrize()
    //this.getRoulette()
  }

  render() {
    return (
      <div className="form_containermc">{console.log(this.state.loading, 'loading')}
        {
          this.state.loading ?
            <>
              <div className="loader-container">
                <div className="spinner"></div>
              </div>
            </>
            :
            <>
              {
                this.state.showspin === false ?
                  <>
                    <Form className="super24-bg">
                      <Col sm="8" md="5">
                        <Row>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Nombre Completo</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Nombre"
                              required
                              onChange={(e) => {
                                this.setState({
                                  name: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <br />
                        <br />
                     {/*    <Row>
                          <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Fecha</Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="Date"
                              required
                              onChange={(e) => {
                                this.setState({
                                  date: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <br />
                        <br /> */}
                         <Row>
                          <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Documento DPI/Pasaporte</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder=""
                              required
                              onChange={(e) => {
                                this.setState({
                                  document: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                       
                        <br />
                        <br />
                     {/*    <Row>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Fecha de Nacimiento</Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="birth"
                              required
                              onChange={(e) => {
                                this.setState({
                                  birth_date: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <br />
                        <br /> */}
                        <Row>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Correo Electrónico</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Correo Electrónico"
                              required
                              onChange={(e) => {
                                this.setState({
                                  email: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <br />
                        <br />
                        <Row>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Teléfono"
                              maxLength="8"
                              required
                              onChange={(e) => {
                                this.setState({
                                  phone: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <br></br>
                        <br />
                        <Row>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Establecimiento de Compra</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Establecimiento"
                              required
                              onChange={(e) => {
                                this.setState({
                                  business: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <br />
                       {/*  <Row>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>No. Factura</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="No. Factura"
                              required
                              onChange={(e) => {
                                this.setState({
                                  fact: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <br /> */}
                        Selecciona los productos de tu compra y la cantidad de cada uno:
                        <br />
                        <br />
                        <div className="containerProds3">

                          
                          {/* <Row
                            xs="2"
                            sm="8"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "center",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "20px",
                              width: "100%",
                            }}
                          >



                            <div style={{ width: "60%" }}>
                              <Form.Label>Cajas</Form.Label>
                            </div>
                            <div style={{ width: "30%", marginLeft: "1vh" }}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="number"
                                  placeholder="0"
                                  required
                                  onChange={(e) => {

                                    this.setState({ box: e.target.value })

                                  }}
                                />
                              </Form.Group>
                            </div>
                          </Row>
                          <hr />
                          <Form.Label>Otro Producto</Form.Label>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>

                            <div style={{ width: "60%" }}>
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                  type="number"
                                  placeholder="Producto"
                                  required
                                  onChange={(e) => {
                                    this.setState({
                                      otherProd: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Group>
                            </div>
                            <div style={{ width: "20%" }}>
                              <Form.Control
                                type="number"
                                placeholder="0"
                                required
                                onChange={(e) => {
                                  this.setState({
                                    quantity: e.target.value,
                                  });
                                  if (e.target.value > 1) {
                                    this.setState({
                                      checkHandler: true,
                                    });
                                  } else {
                                    this.setState({
                                      checkHandler: false,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <hr /> */}


                          
                          <Form.Label>Listado de Productos</Form.Label>
                          {this._isMounted &&
                            this.state.products &&
                            this.state.products.map((product) => {
                              return (
                                <>
                                  <Row
                                    xs="2"
                                    sm="8"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      textAlign: "center",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: "20px",
                                      width: "100%",
                                    }}
                                  >
                                    <div style={{ width: "60%" }}>
                                      <Form.Label>{product.label}</Form.Label>
                                    </div>
                                    <div style={{ width: "30%", marginLeft: "1vh" }}>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Control
                                          type="number"
                                          placeholder="0"
                                          required
                                          onChange={(e) => {

                                            if (e.target.value > 0) {
                                              this.jsonParser(
                                                product.value,
                                                parseInt(e.target.value)
                                              );
                                            }
                                          }}
                                        />
                                      </Form.Group>
                                    </div>
                                  </Row>
                                </>
                              );
                            })}
                        </div>
                        <br />
                        <br />
                        <Row>
                          <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Foto Factura</Form.Label>
                            <Form.Control
                              type="file"
                              required
                              onChange={(e) => {
                                this.setState({
                                  photo_fact: e.target.files[0],
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <br></br>
                        <br></br>    <br></br>
                        <br></br>
                      </Col>
                      <Col sm="8" md="2">
                        
                        <Row>
                          <Button
                            className="buttD"
                            variant="light"
                            onClick={() => {
                              this.setState({
                                brands: JSON.stringify([...new Set(this.state.brandsArray)]),
                              });
                              this.postForm(JSON.stringify([...new Set(this.state.brandsArray)]));
                            }}
                          >
                            Enviar
                          </Button>
                       
                        </Row>
                      </Col>
                    </Form>
                  </>
                  :
                  <div style={{
                    height: '100vh'
                  }}>
                    <Game lead={this.state.lead} show={this.state.show} data={this.state.productsData}  />
                  </div>
              }
             {/*    <Button
                onClick={() =>
                  this.setState({
                    showspin: !this.state.showspin,
                    show: true
                  })
                }>
                Show
              </Button>   */}
            </>
        }

      </div>
    );
  }
}
export default super24;
