import {
    withRouter,
    Switch,
    Route,
  
  } from "react-router-dom";
import { useState } from 'react';

import Bodegona from './forms/bodegona.jsx'
import Multicanal from './forms/multicanal.jsx'
import Torre from './forms/torre.jsx'
import Shell from './forms/shell.jsx'
import Super7 from './forms/super7.jsx'
import Super24 from './forms/super24.jsx'
import Texaco from './forms/texaco.jsx'
import Walmart from './forms/walmart.jsx'
import Uno from './forms/Uno.jsx'
import Game from './game/game.jsx'
import AppSticker from "./sticker/App.jsx"
import Print from "./sticker/Print.jsx"

const GameView = () =>(
    <div>
        <Game/>
    </div>
)

const BodegonaForm = () =>(
    <div>
        <Bodegona/>
    </div>
)

const MulticanalForm = () =>(
    <div style={{height:'100%'}}>
        <Multicanal/>
    </div>
)

const TorreForm = () =>(
    <div>
        <Torre/>
    </div>
)

const ShellForm = () =>(
    <div>
        <Shell/>
    </div>
)

const Super7Form = () =>(
    <div>
        <Super7/>
    </div>
)

const Super24Form = () =>(
    <div  >
        {/* <Print></Print> */}
        <AppSticker/>
    </div>
)

const TexacoForm = () =>(
    <div>
        <Texaco/>
    </div>
)

const WalmartForm = () =>(
    <div>
        <Walmart/>
    </div>
)

const UnoForm = () =>(
    <div>
        <Uno/>
    </div>
)


const ContentBodegona = withRouter(()=><BodegonaForm/>)
const ContentMulticanal = withRouter(()=><MulticanalForm/>)
const ContentTorre = withRouter(()=><TorreForm/>)
const ContentShell = withRouter(()=><ShellForm/>)
const ContentSuper7 = withRouter(()=><Super7Form/>)
const ContentSuper24 = withRouter(()=><Super24Form />)
const ContentTexaco = withRouter(()=><TexacoForm/>)
const ContentWalmart = withRouter(()=><WalmartForm/>)
const ContentUno = withRouter(()=><UnoForm/>)
const ContentGame = withRouter(()=><GameView/>)

const App = () => {
    const [selectedForm, setSelectedForm] = useState();
    return(
        <Switch>
            {/* <Route exact path='/bodegona-form' component={()=> <ContentBodegona selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/>
            <Route exact path='/multicanal-form' component={()=> <ContentMulticanal selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/>
            <Route exact path='/la-torre-form' component={()=> <ContentTorre selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/>
            <Route exact path='/shell-form' component={()=> <ContentShell selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/>
            <Route exact path='/super24-form' component={()=> <ContentSuper24 selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/> */}
            <Route exact path='/' component={()=> <ContentSuper24   selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/>
            <Route exact path='/verano' component={()=> <ContentSuper7 selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/>
            {/* <Route exact path='/texaco-form' component={()=> <ContentTexaco selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/>
            <Route exact path='/walmart-form' component={()=> <ContentWalmart selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/>
            <Route exact path='/uno-form' component={()=> <ContentUno selectedForm={selectedForm} setSelectedForm={setSelectedForm} />}/>
            <Route exact path='/game' component={()=> <ContentGame />}/> */}
        </Switch>
    )

}

export default App;