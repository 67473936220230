import React, { Component } from "react";
import { createCanvas, loadImage } from "canvas";

class ImageEditor extends Component {
  state = {
    uploadedImage: null,
    text: "",
  };

  onImageUpload = (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ uploadedImage: reader.result });
    };
    reader.readAsDataURL(image);
  };

  onTextChange = (event) => {
    this.setState({ text: event.target.value });
  };

  onMouseMove = (event) => {
    const { uploadedImage, text } = this.state;

    if (!uploadedImage) return;

    const canvas = createCanvas(uploadedImage.width, uploadedImage.height);
    const ctx = canvas.getContext("2d");

    loadImage(uploadedImage).then((img) => {
      ctx.drawImage(img, 0, 0);
      ctx.fillStyle = "black";
      ctx.font = "16px sans-serif";
      ctx.fillText(text, event.nativeEvent.offsetX, event.nativeEvent.offsetY);

      const newImage = canvas.toDataURL();
      this.setState({ uploadedImage: newImage });
    });
  };

  render() {
    const { uploadedImage, text } = this.state;

    return (
      <div>
        <input
          type="file"
          accept="image/*"
          onChange={this.onImageUpload}
        />

        <input
          type="text"
          value={text}
          onChange={this.onTextChange}
          placeholder="Agrega un Texto"
        />
{console.log(uploadedImage && uploadedImage)}
        {uploadedImage && (
          <img
            src={uploadedImage}
            style={{ width: "100%" }}
            onMouseMove={this.onMouseMove}
          />
        )}
      </div>
    );
  }
}

export default ImageEditor;